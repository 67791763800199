import { HTMLChakraProps, chakra, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

export const Logo = (props: HTMLChakraProps<'svg'>) => {
  return (
    <chakra.svg
      height='8'
      width='auto'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path fill="#FFFFFF" d="M14.8,30C8.8,30,2.3,27,2.3,18.6V2.4C2.3,0.8,3,0,4.4,0h6.1c1.3,0,2.1,0.9,2.1,2.4v11.2l0,1.1c0,0.5,0,0.9,0.1,1.3c0.1,0.6,0.3,1,0.7,1.3l0.1,0.1c0.3,0.2,0.8,0.3,1.5,0.3c0.7,0,1.2-0.1,1.6-0.4c0.4-0.3,0.6-0.8,0.8-1.4c0.1-0.3,0.1-0.7,0.1-1.3c0-3.1,0-12.5,0-12.5C17.5,0.8,18.3,0,19.5,0h6.1c2.1,0,2.1,1.8,2.1,2.4c0,0,0,11.5,0,16.2C27.7,27,21.2,30,15.2,30H14.8z"/>
      <path fill="#486589" d="M17.1,24.4h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C16,24.9,16.5,24.4,17.1,24.4z"/>
      <path fill="#9D4C3D" d="M23.5,11.3h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C22.4,11.8,22.9,11.3,23.5,11.3z"/>
      <path fill="#1B325E" d="M8.9,23.2h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C7.8,23.6,8.3,23.2,8.9,23.2z"/>
      <path fill="#9D4C3D" d="M8.7,2.3h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1C8.1,5.5,7.6,5,7.6,4.4v-1C7.6,2.8,8.1,2.3,8.7,2.3z"/>
      <path fill="#486589" d="M6.5,20.3h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C5.4,20.8,5.9,20.3,6.5,20.3z"/>
      <path fill="#486589" d="M15.8,19.4h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C14.8,19.9,15.3,19.4,15.8,19.4z"/>
      <path fill="#1B325E" d="M8.5,13.8h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C7.4,14.3,7.9,13.8,8.5,13.8z"/>
      <path fill="#1B325E" d="M20.3,8.5h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C19.2,9,19.7,8.5,20.3,8.5z"/>
      <path fill="#1B325E" d="M6.4,3.5h1C8,3.5,8.5,4,8.5,4.6v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C5.3,4,5.8,3.5,6.4,3.5z"/>
      <path fill="#1B325E" d="M8.9,5.9h1C10.5,5.9,11,6.4,11,7v1c0,0.6-0.5,1.1-1.1,1.1h-1C8.3,9.1,7.8,8.6,7.8,8V7C7.8,6.4,8.3,5.9,8.9,5.9z"/>
      <path fill="#9D4C3D" d="M18.7,18.7h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C17.6,19.1,18.1,18.7,18.7,18.7z"/>
      <path fill="#9D4C3D" d="M13.2,19.6h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C12.1,20.1,12.6,19.6,13.2,19.6z"/>
      <path fill="#1B325E" d="M14.4,24.4h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C13.3,24.9,13.8,24.4,14.4,24.4z"/>
      <path fill="#1B325E" d="M11.6,24h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C10.5,24.5,11,24,11.6,24z"/>
      <path fill="#1B325E" d="M21.5,21h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C20.4,21.5,20.9,21,21.5,21z"/>
      <path fill="#1B325E" d="M8,21.6h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1H8c-0.6,0-1.1-0.5-1.1-1.1v-1C6.9,22,7.4,21.6,8,21.6z"/>
      <path fill="#1B325E" d="M8.5,17.3h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C7.4,17.8,7.9,17.3,8.5,17.3z"/>
      <path fill="#1B325E" d="M17.2,21.4h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C16.1,21.9,16.6,21.4,17.2,21.4z"/>
      <path fill="#9D4C3D" d="M14.6,22.4h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C13.6,22.9,14.1,22.4,14.6,22.4z"/>
      <path fill="#1B325E" d="M10.8,20.9h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C9.7,21.4,10.2,20.9,10.8,20.9z"/>
      <path fill="#F16F24" d="M22.9,8.7h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C21.8,9.2,22.3,8.7,22.9,8.7z"/>
      <path fill="#1B325E" d="M5.9,17.7h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C4.8,18.2,5.3,17.7,5.9,17.7z"/>
      <path fill="#9D4C3D" d="M22.7,18.4h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C21.6,18.8,22.1,18.4,22.7,18.4z"/>
      <path fill="#1B325E" d="M5.5,9.5h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C4.5,10,4.9,9.5,5.5,9.5z"/>
      <path fill="#486589" d="M8.2,8.1h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C7.2,8.6,7.7,8.1,8.2,8.1z"/>
      <path fill="#9D4C3D" d="M5.9,5.9h1C7.5,5.9,8,6.4,8,7v1C8,8.6,7.6,9.1,7,9.1h-1C5.3,9.1,4.8,8.6,4.8,8V7C4.8,6.4,5.3,5.9,5.9,5.9z"/>
      <path fill="#486589" d="M9.4,19h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C8.4,19.5,8.8,19,9.4,19z"/>
      <path fill="#F16F24" d="M5,7.5h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1H5C4.4,10.7,4,10.2,4,9.6v-1C4,8,4.4,7.5,5,7.5z"/>
      <path fill="#9D4C3D" d="M6.6,12.3h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C5.5,12.8,6,12.3,6.6,12.3z"/>
      <path fill="#F16F24" d="M6.9,15.3h1c0.6,0,1.1,0.5,1.1,1.1v1C9,18,8.6,18.5,8,18.5h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C5.8,15.7,6.3,15.3,6.9,15.3z"/>
      <path fill="#9D4C3D" d="M6.8,19.6h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C5.7,20,6.2,19.6,6.8,19.6z"/>
      <path fill="#486589" d="M5.6,1.8h1c0.6,0,1.1,0.5,1.1,1.1v1C7.8,4.5,7.3,5,6.7,5h-1C5,5,4.6,4.5,4.6,3.9v-1C4.6,2.3,5,1.8,5.6,1.8z"/>
      <path fill="#9D4C3D" d="M20.5,11.1h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C19.4,11.6,19.9,11.1,20.5,11.1z"/>
      <path fill="#9D4C3D" d="M23.7,4.5h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C22.6,4.9,23.1,4.5,23.7,4.5z"/>
      <path fill="#1B325E" d="M20.7,18.5h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C19.6,18.9,20.1,18.5,20.7,18.5z"/>
      <path fill="#1B325E" d="M22.4,16.1h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C21.3,16.6,21.8,16.1,22.4,16.1z"/>
      <path fill="#1B325E" d="M23.3,2.7h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C22.2,3.2,22.7,2.7,23.3,2.7z"/>
      <path fill="#486589" d="M19.7,15.7h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C18.6,16.2,19.1,15.7,19.7,15.7z"/>
      <path fill="#1B325E" d="M22.8,12.9h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C21.8,13.4,22.2,12.9,22.8,12.9z"/>
      <path fill="#486589" d="M21.2,5.1h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C20.1,5.6,20.6,5.1,21.2,5.1z"/>
      <path fill="#1B325E" d="M8,10.3h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1H8c-0.6,0-1.1-0.5-1.1-1.1v-1C6.9,10.8,7.4,10.3,8,10.3z"/>
      <path fill="#1B325E" d="M5.3,14.5h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C4.2,15,4.7,14.5,5.3,14.5z"/>
      <path fill="#F16F24" d="M19.6,21.4h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C18.5,21.9,19,21.4,19.6,21.4z"/>
      <path fill="#1B325E" d="M19.5,23.3h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C18.4,23.7,18.9,23.3,19.5,23.3z"/>
      <path fill="#F16F24" d="M10.5,22.9h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C9.4,23.3,9.9,22.9,10.5,22.9z"/>
      <path fill="#1B325E" d="M12.5,21.7h1c0.6,0,1.1,0.5,1.1,1.1v1c0,0.6-0.5,1.1-1.1,1.1h-1c-0.6,0-1.1-0.5-1.1-1.1v-1C11.4,22.1,11.9,21.7,12.5,21.7z"/>
    </chakra.svg>
  )
}

export default Logo
